<template>
  <div class="text-black">
    <div class="card border-greyborder p-3" style="border-radius:10px">
      <div class="d-flex">
        <div class="c-avatar">
          <img
            alt=""
            :src="logo"
            class="img-fluid"
            onerror="this.onerror=null;this.src='https://cdn-icons-png.flaticon.com/512/869/869636.png';"
          />
        </div>
        <div style="padding-left:10px">
          <h3 class="font-weight-bold">
            {{ shopName }}
          </h3>
          <router-link
            style="text-decoration:none"
            :to="`smart-menu/${shopLineObjectId}/getshop?uid=${uid}`"
          >
            <small class="text-description">See details</small>
          </router-link>
        </div>
      </div>

      <hr />
      <a data-toggle="modal" data-target="#modalPBottom">
        <div class="d-flex justify-content-between">
          <h6 class="mb-1 font-weight-bold text-grey">
            <CIcon name="cil-home" class="mb-1"></CIcon> {{ branchName }}
          </h6>
          <small class="text-grey">เลือกสาขา >></small>
        </div>
      </a>
    </div>

    <div class="card">
      <table aria-describedby="">
        <tr>
          <td>
            <div class="searchbar">
              <input
                type="text"
                class="form-control input-sm"
                placeholder="ค้นหาสินค้าโดยชื่อ..."
                v-model="searchVal"
              />
              <button type="submit" class="btn btnsearch btn-sm text-right">
                <em class="fas fa-search"></em> ค้นหา
              </button>
            </div>
          </td>
          <td style="width:12%" class="text-grey">
            <small style="font-size: 14px">
              <span @click="handleDisplay('list')">
                <CIcon name="cil-list"></CIcon>
              </span>
              <span @click="handleDisplay('grid')" style="float: right">
                <CIcon name="cil-grid"></CIcon>
              </span>
            </small>
          </td>
        </tr>
      </table>

      <div
        class="d-flex flex-row"
        style="overflow-x:auto;"
        v-if="categories.length > 0"
      >
        <table aria-describedby="">
          <tr>
            <td
              style="padding:2px;"
              v-for="category in categories"
              :key="category.objectId"
              @click="goToByCategoryLists(category.objectId, category.name)"
            >
              <div
                class="circle shadow-menu"
                :style="{
                  'background-color': getCategoryColor(category.indexColor),
                }"
              >
                <h3>{{ catIcon(category.name) }}</h3>
              </div>
              <h6 class="text-center" style="font-size:10px">
                {{ category.name }}
              </h6>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <grid-menu
      v-bind:searchVal="searchVal"
      v-bind:display="display"
    ></grid-menu>
    <br />

    <div class="modal fade modal-p-bottom" id="modalPBottom">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body py-3">
            <p class="text-center">
              <strong>เลือกสาขา</strong>
            </p>
            <ul class="list-group">
              <li
                @click="shopHandle(shop.objectId)"
                class="list-group-item"
                v-for="shop in shopLineOA"
                :key="shop.objectId"
                :value="shop.objectId"
                data-dismiss="modal"
              >
                <CIcon name="cil-home" class="mb-1"></CIcon>
                {{ shop.branchName }}
                <span
                  v-if="shopLineObjectId === shop.objectId"
                  style="float:right"
                  class="text-success"
                >
                  <em class="fas fa-check"></em>
                </span>
              </li>
            </ul>
          </div>
          <div class="modal-footer"></div>
        </div>
      </div>
    </div>

    <div class="modal fade modal-p-bottom" id="categoryModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body py-3">
            <category-lists
              v-bind:shopLineObjectId="shopLineObjectId"
            ></category-lists>
          </div>
        </div>
      </div>
    </div>

    <footer class="fixed-bottom mb-6" v-if="categories.length > 0">
      <a
        class="categorybtn text-success"
        data-toggle="modal"
        data-target="#categoryModal"
      >
        <em class="fas fa-layer-group"></em>
      </a>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import poscrm from '@/services/poscrm'
import util from '@/util/util'
import CategoryLists from './CategoryLists'
import GridMenu from './GridMenu'
import '@/util/menu.css'

export default {
  components: {
    CategoryLists,
    GridMenu,
  },
  data() {
    return {
      products: [],
      categories: [],
      totally: 0,
      searchVal: '',
      display: 'grid',
    }
  },
  computed: {
    ...mapGetters(['shopLineOA', 'shopLineObjectId']),
    uid() {
      return this.$route.query.uid
    },
    shopLineObjectId: {
      get() {
        return this.$store.getters.shopLineObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShopLineOA', newValue)
      },
    },
    shopName() {
      let name = ''
      if (this.shopLineOA.length > 0) {
        name = this.shopLineOA[0].shopName
      }
      document.title = name
      return name
    },
    branchName() {
      if (this.shopLineOA.length > 0) {
        let name = this.shopLineOA.filter(
          (item) => item.objectId === this.shopLineObjectId
        )
        return name[0].branchName
      } else {
        return ''
      }
    },
    logo() {
      if (this.shopLineOA.length > 0) {
        return this.shopLineOA[0].remoteImagePath
      } else {
        return ''
      }
    },
  },
  created() {
    this.getCategory()
  },
  methods: {
    ...util,
     handleDisplay(opt) {
      this.display = opt
    },
    catIcon(catName) {
      return catName.charAt(0).toUpperCase()
    },
    getCategoryColor(indexColor) {
      return util.generateColor(indexColor)
    },
    shopHandle(objectId) {
      this.shopLineObjectId = objectId
      this.getCategory()
    },
    goToByCategoryLists(objectId, name) {
      localStorage.setItem('categoryName', name)
      this.$router.push('/smart-menu/' + objectId + '/category?uid=' + this.uid)
    },
    getCategory() {
      let uid = this.uid
      let params = {
        shopObjectId: this.shopLineObjectId,
      }

      poscrm
        .get('/api/v1.0/' + uid + '/Shop/getcategory/view', { params: params })
        .then((res) => {
          this.categories = res.data.data
        })
    },
  },
}
</script>
